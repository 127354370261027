import React from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "popper.js/dist/umd/popper.min.js";

import Login from "./pages/login/Login";
import AuthorDashboard from "./pages/dashboard/AuthorDashboard";

import AuthorRegistration from "./pages/author_registration/AuthorRegistration";
import ViewAuthorRegistration from "./pages/author_registration/ViewAuthorRegistration";
import AuthorPI from "./pages/author_registration/AuthorPI";

import PaperSubmission from "./pages/paper_submission/PaperSubmission";
import ViewPsAuthor from "./pages/paper_submission/ViewPsAuthor";
import ViewPsEditor from "./pages/paper_submission/ViewPsEditor";
import ViewPsReviewer from "./pages/paper_submission/ViewPsReviewer";

import ReviewerRegistration from "./pages/reviewer_registration/ReviewerRegistration";
import ViewReviewerRegistration from "./pages/reviewer_registration/ViewReviewerRegistration";
import ReviewerPI from "./pages/reviewer_registration/ReviewerPI";

import EditorRegistration from "./pages/editor_registration/EditorRegistration";
import ViewEditorRegistration from "./pages/editor_registration/ViewEditorRegistration";

import { Redirect } from "react-router-dom";
import { BrowserRouter, Route } from "react-router-dom";

export default function Router() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/author-dashboard" component={AuthorDashboard} />

      <Route exact path="/author-register" component={AuthorRegistration} />
      <Route exact path="/author-pi" component={AuthorPI} />
      <Route exact path="/reviewer-pi" component={ReviewerPI} />
      <Route
        exact
        path="/view-author-register"
        component={ViewAuthorRegistration}
      />
      <Route exact path="/reviewer-register" component={ReviewerRegistration} />
      <Route
        exact
        path="/view-reviewer-register"
        component={ViewReviewerRegistration}
      />
      <Route exact path="/editor-register" component={EditorRegistration} />
      <Route
        exact
        path="/view-editor-register"
        component={ViewEditorRegistration}
      />

      <Route exact path="/paper-submission" component={PaperSubmission} />
      <Route exact path="/view-ps-author" component={ViewPsAuthor} />
      <Route exact path="/view-ps-editor" component={ViewPsEditor} />
      <Route exact path="/view-ps-reviewer" component={ViewPsReviewer} />

      <Route exact path="/dashboard" component={AuthorDashboard} />

      {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}
    </BrowserRouter>
  );
}
