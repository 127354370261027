import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import { getReviewerPI } from "../../redux/actions/reviewerRegisterAction";
import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";
import Select from "react-select";
import { saveAs } from "file-saver";
import axios from "axios";
import download from "../../resources/img/download.png";
import SidebarReviewer from "../../components/SidebarReviewer";
import { makeStyles } from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function ReviewerPI() {
  const classes = useStyles();

  const [id, setid] = useState("");

  const [errors, seterrors] = useState([]);
  const [data, setData] = useState([]);

  const userState = useSelector((state) => state.loginUserReducer);

  console.log("+++++++++++ CURRENT USER", userState);
  const { error, loading, currentUser } = userState;

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      console.log("-------------- DECODED", decoded.data[0]);
      var { type, username } = decoded.data[0];

      if (type !== "reviewer") {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
    console.log("+++++++++++ USER", username);
    dispatch(getReviewerPI(username)).then((res) => {
      console.log("res", res.response.data.results[0]);
      setData(res.response.data.results);
    });
  }, []);

  console.log("!!!!--DATA-!!!", data);

  // useEffect(() => {
  //   setData();
  // }, []);

  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <SidebarReviewer title="Personal Information" />
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item xs={10} md={5}>
          <Grid style={{ textAlign: "center" }}></Grid>
          <br />
          {data.map((row, index) => (
            <Card
              className={classes.root}
              style={{ paddingLeft: "3rem", color: "#130f40" }}
              key={index}
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Reviewer Details
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <h6 size="small" color="primary">
                  Name :
                </h6>
                <h6 size="small" color="primary">
                  {row.title}. {row.first_name} {row.last_name}
                </h6>
              </CardActions>

              <CardActions>
                <h6>Designation :</h6>
                <h6>{row.designation}</h6>
              </CardActions>

              <CardActions>
                <h6>Institute/Organization :</h6>
                <h6>{row.ins_org}</h6>
              </CardActions>

              <CardActions>
                <h6>Address :</h6>
                <h6>{row.address}</h6>
              </CardActions>

              <CardActions>
                <h6>City :</h6>
                <h6>{row.city}</h6>
              </CardActions>

              <CardActions>
                <h6>State :</h6>
                <h6>{row.state}</h6>
              </CardActions>

              <CardActions>
                <h6>Zip/Postal Code :</h6>
                <h6>{row.zip_postal_code}</h6>
              </CardActions>

              <CardActions>
                <h6>Country :</h6>
                <h6>{row.country}</h6>
              </CardActions>

              <CardActions>
                <h6>Email :</h6>
                <h6>{row.email}</h6>
              </CardActions>

              <CardActions>
                <h6>Contact Number :</h6>
                <h6>{row.contact_number}</h6>
              </CardActions>

              <CardActions>
                <h6>Username :</h6>
                <h6>{row.username}</h6>
              </CardActions>
            </Card>
          ))}
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
