import axios from "axios";
import { apiUrl } from "../../config";

// +++++++++++ Author +++++++++++ //

export const addPsAuthor = (ps_data) => async (dispatch) => {
  console.log("ACTION------>", ps_data);
  //const ps = { PS_name, password, email, contact_number, postal_address, country, qualification, affiliation, university_company, research_interest, lqc_cv, scopus_id, orcid_id, google_scholar_id, wos_researcher_id }

  const formData = new FormData();
  formData.append("paper_title", ps_data.paper_title);
  formData.append("abstract", ps_data.abstract);
  formData.append("keywords", ps_data.keywords);
  formData.append("paper_file", ps_data.paper_file);
  formData.append("name", ps_data.name);
  formData.append("designation", ps_data.designation);
  formData.append("address", ps_data.address);
  formData.append("email", ps_data.email);
  formData.append("country", ps_data.country);
  formData.append("username", ps_data.username);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
      //'auth':`${JSON.parse(localStorage.getItem('auth'))}`
      // 'auth':`${JSON.parse(token)}`
    },
  };

  dispatch({ type: "ADD_PS_REQUEST" });
  try {
    const response = await axios.post(
      `${apiUrl}/api/paper-submission/create`,
      formData,
      config
    );
    console.log("+++ add", response);
    dispatch({ type: "ADD_PS_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_PS_FAILED", payload: error });
  }
};

export const allPsAuthorClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_PS_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_PS_FAILED", payload: error });
  }
};

export const getAllPsAuthorLength = (user) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallPsAuthor_length/${user}`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllPsAuthor = (offset, row_count, user) => async (dispatch) => {
  dispatch({ type: "GET_PS_REQUEST" });
  console.log("---------USER---------", user);
  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallPsAuthor/${offset}/${row_count}/${user}`
    );
    //console.log("%%%222%%%", response);
    dispatch({ type: "GET_PS_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_PS_FAILED", payload: error });
  }
};

// +++++++++++ Reviewer +++++++++++ //
export const allPsReviewerClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_PS_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_PS_FAILED", payload: error });
  }
};

export const getAllPsReviewerLength = (user) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallPsReviewer_length/${user}`
    );
    //console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllPsReviewer = (offset, row_count, user) => async (
  dispatch
) => {
  dispatch({ type: "GET_PS_REQUEST" });
  console.log("---------USER---------", user);
  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallPsReviewer/${offset}/${row_count}/${user}`
    );
    //console.log("%%%222%%%", response);
    dispatch({ type: "GET_PS_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_PS_FAILED", payload: error });
  }
};

export const updatePsReviewer = (acc) => async (dispatch) => {
  console.log("00000000__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/paper-submission/psReviewerUpdate`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

// ========== *********** =========== //

export const getAllReviewerNames = () => async (dispatch) => {
  dispatch({ type: "GET_RNAME_REQUEST" });
  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallReviewerNames`
    );
    //console.log('##123456##',response.data.results)
    dispatch({ type: "GET_RNAME_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_RNAME_FAILED", payload: error });
  }
};

// +++++++++++ Editor ++++++++++++ /

export const allPsEditorClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_PS_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_PS_FAILED", payload: error });
  }
};

export const getAllPsEditorLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallPsEditor_length`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllPsEditor = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_PS_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/paper-submission/getallPsEditor/${offset}/${row_count}`
    );

    dispatch({ type: "GET_PS_SUCCESS", payload: response.data.results });
  } catch (error) {
    dispatch({ type: "GET_PS_FAILED", payload: error });
  }
};

export const updatePsEditor = (acc) => async (dispatch) => {
  console.log("123456789__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/paper-submission/psEditorUpdate`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const updatePsEditor_1 = (acc) => async (dispatch) => {
  console.log("1000000__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/paper-submission/psEditorUpdate_1`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const updatePsEditor_2 = (acc) => async (dispatch) => {
  console.log("1000000__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/paper-submission/psEditorUpdate_2`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};
export const updatePsEditor_3 = (acc) => async (dispatch) => {
  console.log("1000000__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/paper-submission/psEditorUpdate_3`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

// ========== *********** =========== //

export const deletePS = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/PS/delete`, { accid });
    return { response };
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchPS = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(`${apiUrl}/api/PS/search`, data);
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};
