import { combineReducers } from "redux";
//import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
//import thunk from "redux-thunk";
//import {composeWithDevTools} from 'redux-devtools-extension'

import { loginUserReducer } from "./reducers/userReducers";

import {
  getAddAuthorRegisterReducer,
  getAllAuthorRegisterReducer,
} from "./reducers/authorRegisterReducers";
import {
  getAddReviewerRegisterReducer,
  getAllReviewerRegisterReducer,
} from "./reducers/reviewerRegisterReducers";
import {
  getAddEditorRegisterReducer,
  getAllEditorRegisterReducer,
} from "./reducers/editorRegisterReducers";
import {
  getAddPaperSubmissionReducer,
  getAllPaperSubmissionReducer,
  getAllReviewerNamesReducer,
} from "./reducers/paperSubmissionreducers";
import { persistReducer, PERSIST } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: "my-research-super-secret-key",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const finalReducer = combineReducers({
  loginUserReducer: loginUserReducer,
  getAddAuthorRegisterReducer: getAddAuthorRegisterReducer,
  getAllAuthorRegisterReducer: getAllAuthorRegisterReducer,
  getAddReviewerRegisterReducer: getAddReviewerRegisterReducer,
  getAllReviewerRegisterReducer: getAllReviewerRegisterReducer,
  getAddEditorRegisterReducer: getAddEditorRegisterReducer,
  getAllEditorRegisterReducer: getAllEditorRegisterReducer,
  getAddPaperSubmissionReducer: getAddPaperSubmissionReducer,
  getAllPaperSubmissionReducer: getAllPaperSubmissionReducer,
  getAllReviewerNamesReducer: getAllReviewerNamesReducer,
});

const persistedReducer = persistReducer(persistConfig, finalReducer);

//const composeEnhancers = composeWithDevTools({})
//const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});

export default store;
