import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import { Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import {
  getAllAuthorRegister,
  allAuthorRegisterClear,
  getAllAuthorRegisterLength,
  deleteAuthorRegister,
  updateAuthorRegister,
  searchAuthorRegister,
} from "../../redux/actions/authorRegisterAction";

import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";
// import Select from "react-select";
import { saveAs } from "file-saver";
import axios from "axios";
import "./pagestyle.css";
import download from "../../resources/img/download.png";
import Sidebar from "../../components/Sidebar";

import { makeStyles } from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function ViewRegisteration() {
  const classes = useStyles();

  const [is_edit, setis_edit] = useState(false);
  const [id, set_id] = useState("");

  const [title, set_title] = React.useState("");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [designation, set_designation] = useState("");
  const [ins_org, set_ins_org] = useState("");
  const [address, set_address] = useState("");
  const [city, set_city] = useState("");
  const [state, set_state] = useState("");
  const [zip_postal_code, set_zip_postal_code] = useState("");
  const [country, set_country] = useState("");
  const [email, set_email] = useState("");
  const [contact_number, set_contact_number] = useState("");
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");

  const [errors, seterrors] = useState([]);

  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const author_register_state = useSelector(
    (state) => state.getAllAuthorRegisterReducer
  );
  const { author_register } = author_register_state;
  console.log("!!!!!!!", author_register);

  const userState = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userState;

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      //setuser(decoded.username);
      if (decoded.role !== "admin") {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }

    dispatch(allAuthorRegisterClear());
    dispatch(getAllAuthorRegisterLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllAuthorRegister(1, itemsPerPage));
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(author_register);
  }, [author_register]);

  // useEffect(() => {
  //   dispatch(allOrdersClear())
  //   dispatch(getAllOrdersLength()).then((response)=>{
  //    setTot(response.response.data.results[0].tot)
  //   })
  //   dispatch(getAllOrders(1,itemsPerPage))
  //   dispatch(getAllCompanyNames())
  //   handleClientList()
  // },[])

  const dispatch = useDispatch();

  const handleEdit = (val) => {
    console.log("%%%%%%%%%%%%%", val.id);
    setis_edit(true);
    set_id(val.id);

    set_title(val.title);
    set_first_name(val.first_name);
    set_last_name(val.last_name);
    set_designation(val.designation);
    set_ins_org(val.ins_org);
    set_address(val.address);
    set_city(val.city);
    set_state(val.state);
    set_zip_postal_code(val.zip_postal_code);
    set_country(val.country);
    set_email(val.email);
    set_contact_number(val.contact_number);
    set_username(val.username);
    set_password(val.password);
    setOpen(true);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleDelete = (acc) => {
    console.log("####", acc.id);
    var option = window.confirm(
      `Are You Sure want to delete ${acc.first_name}`
    );
    if (option) {
      dispatch(deleteAuthorRegister(acc.id)).then((response) => {
        setTot(response.response.data.results[0].tot);
      });
      dispatch(getAllAuthorRegister(1, itemsPerPage));
    }
  };

  const handleSearch = (val) => {
    setsearch(val);
    dispatch(searchAuthorRegister(val))
      .then((res) => {
        setData(res.response.data.results);
      })
      .catch((err) => {
        setsearch("");
        dispatch(getAllAuthorRegister(1, itemsPerPage));
      });
  };

  const handleClear = () => {
    set_title("");
    set_first_name("");
    set_last_name("");
    set_designation("");
    set_ins_org("");
    set_address("");
    set_city("");
    set_state("");
    set_zip_postal_code("");
    set_country("");
    set_email("");
    set_contact_number("");
    set_username("");
    set_password("");
  };

  const onChangeContact = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      //this.setState({value: e.target.value})
      set_contact_number(e.target.value);
      if (e.target.value.length > 10) {
        errors.contact_number = true;
      } else {
        errors.contact_number = false;
      }
    }
  };
  const onChangeEmail = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    set_email(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      errors.email = true;
    } else {
      errors.email = false;
    }
  };

  const handleCheck = (e) => {
    this.setState({ checked: e.target.checked }, () => {
      if (this.state.checked === true) {
        this.setState({ page: "consentpage" });
      } else {
        this.setState({ page: "doctorspage" });
      }
    });
  };

  const Submit = () => {
    const reg_data = {
      id,
      title,
      first_name,
      last_name,
      designation,
      ins_org,
      address,
      city,
      state,
      zip_postal_code,
      country,
      email,
      contact_number,
      username,
      password,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updateAuthorRegister(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
        dispatch(getAllAuthorRegister(1, itemsPerPage));
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllAuthorRegister(Number(event.target.id), itemsPerPage));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(getAllAuthorRegister(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //
  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.title) {
      errors.title = "*";
    }
    if (!data.first_name) {
      errors.first_name = "*";
    }
    if (!data.last_name) {
      errors.last_name = "*";
    }
    if (!data.designation) {
      errors.designation = "*";
    }
    if (!data.ins_org) {
      errors.ins_org = "*";
    }
    if (!data.address) {
      errors.address = "*";
    }
    if (!data.city) {
      errors.city = "*";
    }
    if (!data.state) {
      errors.state = "*";
    }
    if (!data.zip_postal_code) {
      errors.zip_postal_code = "*";
    }
    if (!data.country) {
      errors.country = "*";
    }
    if (!data.contact_number || data.contact_number.length > 10) {
      errors.contact_number = true;
    }

    if (!data.email) {
      errors.email = true;
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(data.email)) {
        errors.email = true;
      }
    }
    if (!data.username) {
      errors.username = "*";
    }
    if (!data.password) {
      errors.password = "*";
    }

    return errors;
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <Sidebar title=" View Author Details" />
          </Grid>
          <Grid item xs={11}>
            <Grid style={{ textAlign: "center" }}>
              <TextField
                className="txtfld"
                id="outlined-basic"
                variant="outlined"
                fullWidth={false}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                value={search}
                placeholder="Search"
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <br />

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#0abde3" }}>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Designation</TableCell>
                    <TableCell align="left">Institute/Organization</TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="left">City</TableCell>
                    <TableCell align="left">State</TableCell>
                    <TableCell align="left">Zip/Postal Code</TableCell>
                    <TableCell align="left">Country</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Contact Number</TableCell>
                    <TableCell align="left">UserName</TableCell>
                    <TableCell align="left"> </TableCell>
                    <TableCell align="left"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {row.title}. {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell align="left">{row.designation}</TableCell>
                      <TableCell align="left">{row.ins_org}</TableCell>
                      <TableCell align="left">{row.address}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.state}</TableCell>
                      <TableCell align="left">{row.zip_postal_code}</TableCell>
                      <TableCell align="left">{row.country}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.contact_number}</TableCell>
                      <TableCell align="left">{row.username}</TableCell>
                      <TableCell align="left">
                        <EditIcon
                          onClick={() => {
                            handleEdit(row);
                          }}
                          style={{ color: "#ff9f43" }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <DeleteIcon
                          onClick={() => {
                            handleDelete(row);
                          }}
                          color="secondary"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mt-5">
          <Grid item xs={12}>
            <div className="pageNumbers">
              <button
                onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false}
              >
                {" "}
                &lt;&lt;
              </button>

              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              <button
                onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              >
                {" "}
                &gt;&gt;
              </button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 50px",
              }}
            >
              <button onClick={handleLoadMore} className="loadmore">
                Load More
              </button>
            </div>
          </Grid>
        </Grid>

        {/*  */}
        <Grid>
          <React.Fragment>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle id="max-width-dialog-title">
                {title}. {first_name} {last_name}
              </DialogTitle>
              <DialogContent>
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={10}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={3} className="mt-3">
                          <Grid item xs={12} sm={6} md={3}>
                            <Box>
                              <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-simple-select-label">
                                  Title
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={title}
                                  label="Title"
                                  error={errors.title ? true : false}
                                  onChange={(e) => {
                                    set_title(e.target.value);
                                  }}
                                >
                                  <MenuItem value={"Dr"}>Dr</MenuItem>
                                  <MenuItem value={"Mr"}>Mr</MenuItem>
                                  <MenuItem value={"Mrs"}>Mrs</MenuItem>
                                  <MenuItem value={"Miss"}>Miss</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="First Name"
                              onChange={(e) => {
                                set_first_name(e.target.value);
                              }}
                              value={first_name}
                              variant="outlined"
                              fullWidth={true}
                              autoFocus={true}
                              error={errors.first_name ? true : false}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Last Name"
                              onChange={(e) => {
                                set_last_name(e.target.value);
                              }}
                              value={last_name}
                              variant="outlined"
                              fullWidth={true}
                              autoFocus={false}
                              error={errors.last_name ? true : false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Designation"
                              name="designation"
                              error={errors.designation ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              autoFocus={false}
                              onChange={(e) => {
                                set_designation(e.target.value);
                              }}
                              value={designation}
                            />
                          </Grid>
                        </Grid>
                        <br /> <br />
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Institute / Organization"
                              onChange={(e) => {
                                set_ins_org(e.target.value);
                              }}
                              value={ins_org}
                              variant="outlined"
                              fullWidth={true}
                              autoFocus={false}
                              error={errors.ins_org ? true : false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Address"
                              onChange={(e) => {
                                set_address(e.target.value);
                              }}
                              value={address}
                              variant="outlined"
                              fullWidth={true}
                              autoFocus={false}
                              error={errors.address ? true : false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="City"
                              name="city"
                              error={errors.city ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                set_city(e.target.value);
                              }}
                              value={city}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="State"
                              name="state"
                              error={errors.state ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                set_state(e.target.value);
                              }}
                              value={state}
                            />
                          </Grid>
                        </Grid>
                        <br /> <br />
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Zip / Postal Code"
                              name="zip_postal_code"
                              error={errors.zip_postal_code ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                set_zip_postal_code(e.target.value);
                              }}
                              value={zip_postal_code}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Country"
                              name="country"
                              error={errors.country ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                set_country(e.target.value);
                              }}
                              value={country}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              onChange={(e) => onChangeEmail(e)}
                              variant="outlined"
                              value={email}
                              fullWidth={true}
                              autoFocus={false}
                              error={errors.email ? true : false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Contact Number"
                              // onChange={onChangeContact}
                              onChange={(e) => onChangeContact(e)}
                              variant="outlined"
                              value={contact_number}
                              fullWidth={true}
                              autoFocus={false}
                              error={errors.contact_number ? true : false}
                            />
                          </Grid>
                        </Grid>
                        <br /> <br />
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Username"
                              name="username"
                              error={errors.username ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                set_username(e.target.value);
                              }}
                              value={username}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="outlined-basic"
                              label="Password"
                              type="password"
                              name="password"
                              error={errors.password ? true : false}
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                set_password(e.target.value);
                              }}
                              value={password}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}></Grid>
                        </Grid>
                        <br /> <br />
                        <Grid container spacing={3} className="mt-5">
                          <Grid item xs={12} sm={4}></Grid>
                          <Grid item xs={12} sm={4}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={<SaveIcon />}
                              onClick={Submit}
                            >
                              {is_edit === false ? "Submit" : "Update"}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={4}></Grid>
                        </Grid>
                      </Paper>

                      <Grid className="mt-3"></Grid>
                    </Grid>

                    <Grid item sm={1}></Grid>
                  </Grid>
                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </Grid>
        {/*  */}
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
