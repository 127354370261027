import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import {
  getAllPsAuthor,
  getAllPsAuthorLength,
} from "../../redux/actions/paperSubmissionAction";
import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";
import Select from "react-select";
import { saveAs } from "file-saver";
import axios from "axios";
import download from "../../resources/img/download.png";
import SidebarAuthor from "../../components/SidebarAuthor";
import { makeStyles } from "@material-ui/core/styles";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

export default function ViewPsAuthor() {
  const classes = useStyles();

  const [id, setid] = useState("");

  const [errors, seterrors] = useState([]);

  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const paper_submission_state = useSelector(
    (state) => state.getAllPaperSubmissionReducer
  );
  console.log("!!!!STATE!!!", paper_submission_state);
  const { papers } = paper_submission_state;
  console.log("!!!!!!!", papers);

  const userState = useSelector((state) => state.loginUserReducer);

  console.log("+++++++++++ CURRENT USER", userState);
  const { error, loading, currentUser } = userState;

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      console.log("-------------- DECODED", decoded.data[0]);
      var { type, username } = decoded.data[0];

      if (type !== "author") {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
    //dispatch(allPSUserClear());
    dispatch(getAllPsAuthorLength(username)).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllPsAuthor(1, itemsPerPage, username));
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(papers);
  }, [papers]);

  const dispatch = useDispatch();

  const handleClear = () => {};
  const handleDownload = (fname, fname_og) => {
    console.log("********** *******", fname, fname_og);

    const downloadAs = (url, name) => {
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = name;
          a.click();
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    downloadAs(`${apiUrl}/uploads/${fname}`, fname_og);
  };

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(
      getAllPsAuthor(
        Number(event.target.id),
        itemsPerPage,
        jwt_decode(userState.currentUser).data[0].username
      )
    );
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(
      getAllPsAuthor(
        currentPage,
        itemsPerPage,
        jwt_decode(userState.currentUser).data[0].username
      )
    );
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <SidebarAuthor title="" />
        </Grid>
        <Grid item xs={11}>
          <Grid style={{ textAlign: "center" }}>
            {/* <TextField
              className="txtfld"
              id="outlined-basic"
              variant="outlined"
              fullWidth={false}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={search}
              placeholder="Search"
              style={{ backgroundColor: "white" }}
            /> */}
          </Grid>
          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#0097e6" }}>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Designation</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Country</TableCell>
                  <TableCell align="left">PaperTitle</TableCell>
                  <TableCell align="left">Abstract</TableCell>
                  <TableCell align="left">Keywords</TableCell>
                  <TableCell align="left">Paper</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.designation}</TableCell>
                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.country}</TableCell>
                    <TableCell align="left">{row.paper_title}</TableCell>
                    <TableCell align="left">{row.abstract}</TableCell>
                    <TableCell align="left">{row.keywords}</TableCell>
                    <TableCell align="left">{row.paper_file_og}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-5">
        <Grid item xs={12}>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>

            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
