import axios from "axios";
import { apiUrl } from "../../config";

export const addReviewerRegister = (reg) => async (dispatch) => {
  console.log("ACTION------>", reg);
  dispatch({ type: "ADD_REVIEWER_REGISTER_REQUEST" });
  try {
    const response = await axios.post(
      `${apiUrl}/api/reviewer_register/create`,
      reg
    );
    console.log("+++ add", response);
    dispatch({ type: "ADD_REVIEWER_REGISTER_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_REVIEWER_REGISTER_FAILED", payload: error });
  }
};

export const allReviewerRegisterClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_REVIEWER_REGISTER_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_REVIEWER_REGISTER_FAILED", payload: error });
  }
};

export const getAllReviewerRegisterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/reviewer_register/getallregister_length`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllReviewerRegister = (offset, row_count) => async (
  dispatch
) => {
  dispatch({ type: "GET_REVIEWER_REGISTER_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/reviewer_register/getAllRegisters/${offset}/${row_count}`
    );

    dispatch({
      type: "GET_REVIEWER_REGISTER_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({ type: "GET_REVIEWER_REGISTER_FAILED", payload: error });
  }
};

export const updateReviewerRegister = (acc) => async (dispatch) => {
  console.log("123456789__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/reviewer_register/update`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteReviewerRegister = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/reviewer_register/delete`,
      {
        accid,
      }
    );
    return { response };
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchReviewerRegister = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/reviewer_register/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};

export const getReviewerPI = (username) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/reviewer_register/getReviewerPI/${username}`
    );
    return { response };
  } catch (error) {
    return { error };
  }
};
