import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  TextField,
  Button,
  Checkbox,
  Grid,
  Paper,
  Icon,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import { Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import jwt_decode from "jwt-decode";
import { loginUser } from "../../redux/actions/userActions";
import { addPsAuthor } from "../../redux/actions/paperSubmissionAction";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Navbar from "../../components/AdminNavbar/AdminNavbar";
import SidebarAuthor from "../../components/SidebarAuthor";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";

export default function PaperSubmission() {
  const classes = useStyles();

  const [name, set_name] = useState("");
  const [designation, set_designation] = useState("");
  const [address, set_address] = useState("");
  const [email, set_email] = useState("");
  const [country, set_country] = useState("");
  const [username, set_username] = useState("");
  const [paper_title, set_paper_title] = useState("");
  const [abstract, set_abstract] = useState("");
  const [keywords, set_keywords] = useState("");
  const [paper_file, set_paper_file] = useState("");

  const [errors, seterrors] = useState([]);

  const userState = useSelector((state) => state.loginUserReducer);

  //console.log("+++++++++++ CURRENT USER", userState);
  const { error, loading, currentUser } = userState;

  const dispatch = useDispatch();

  //useEffect(() => {}, []);

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      console.log("-------------- DECODED !@@@!", decoded.data[0]);
      var {
        type,
        first_name,
        designation,
        address,
        email,
        country,
        username,
      } = decoded.data[0];

      if (type !== "author") {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
    set_name(first_name);
    set_designation(designation);
    set_address(address);
    set_email(email);
    set_country(country);
    set_username(username);
  }, []);

  const handleClear = () => {
    set_name("");
    set_designation("");
    set_address("");
    set_email("");
    set_country("");
    set_paper_title("");
    set_abstract("");
    set_keywords("");
    window.location.href = "/paper-submission";
  };

  const Submit = () => {
    const ps_data = {
      name,
      designation,
      address,
      email,
      country,
      username,
      paper_title,
      abstract,
      keywords,
      paper_file,
    };
    console.log("##### PS DATA  ######", ps_data);
    const errors = validate(ps_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(addPsAuthor(ps_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);

    if (!data.name) {
      errors.name = "*";
    }
    if (!data.designation) {
      errors.designation = "*";
    }
    if (!data.address) {
      errors.address = "*";
    }
    if (!data.email) {
      errors.email = "*";
    }
    if (!data.country) {
      errors.country = "*";
    }
    if (!data.paper_title) {
      errors.paper_title = "*";
    }
    if (!data.abstract) {
      errors.abstract = "*";
    }
    if (!data.keywords) {
      errors.keywords = "*";
    }

    return errors;
  };

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h2 style={{ color: "#3f51b5" }}>Paper Submission Form</h2>
          </Paper>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Paper className={classes.paper}>
            <SidebarAuthor title="Paper Submission Form" />
          </Paper>
        </Grid>

        <Grid item xs={10} style={{ marginTop: "8rem" }}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-basic"
                  label="Paper title"
                  onChange={(e) => {
                    set_paper_title(e.target.value);
                  }}
                  value={paper_title}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={true}
                  error={errors.paper_title ? true : false}
                />
                <br /> <br />
                <TextField
                  id="outlined-basic"
                  label="Abstract"
                  onChange={(e) => {
                    set_abstract(e.target.value);
                  }}
                  value={abstract}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.abstract ? true : false}
                />
                <br /> <br />
                <TextField
                  id="outlined-basic"
                  label="Keywords"
                  onChange={(e) => {
                    set_keywords(e.target.value);
                  }}
                  value={keywords}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.keywords ? true : false}
                />
                <br /> <br />
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "1.5rem",
                  }}
                >
                  Upload Paper
                </p>
                <div style={{ marginTop: "-1rem" }}>
                  <input
                    type="file"
                    name="paper_file"
                    style={{ color: "transparent" }}
                    onChange={(e) => {
                      set_paper_file(e.target.files[0]);
                    }}
                  />
                  <p
                    style={{
                      color: "blue",
                      textAlign: "left",
                      paddingLeft: "2rem",
                    }}
                  >
                    {paper_file.name}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
            </Grid>

            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={Submit}
                >
                  {"Submit"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
            </Grid>
          </Paper>

          <Grid className="mt-3"></Grid>
        </Grid>

        <Grid item sm={1}></Grid>
      </Grid>
    </div>
    // <div style={{ backgroundColor: "#fff" }}>
    //   <Sidebar />
    //   {/* <Section>
    //     <>
    //       <h2
    //         className="text-center"
    //         style={{ color: "rgb(30, 55, 153)", margin: "1rem" }}
    //       >
    //         {" "}
    //         Reviewer & Editor Registration Form
    //       </h2>
    //       <ul className="wrapper">
    //         <li className="form-row">
    //           <label htmlFor="name">Reviewer/Editor Name</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="reviewer_editor_name"
    //             error={errors.reviewer_editor_name ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_reviewer_editor_name(e.target.value);
    //             }}
    //             value={reviewer_editor_name}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Password</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             type="password"
    //             name="password"
    //             error={errors.password ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_password(e.target.value);
    //             }}
    //             value={password}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Confirm Password </label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             type="password"
    //             name="confirm_password"
    //             error={errors.confirm_password ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_confirm_password(e.target.value);
    //             }}
    //             value={confirm_password}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">E-Mail</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="email"
    //             error={errors.email ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_email(e.target.value);
    //             }}
    //             value={email}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Contact Number</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="contact_number"
    //             error={errors.country ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_contact_number(e.target.value);
    //             }}
    //             value={contact_number}
    //           />
    //         </li>

    //         <li className="form-row">
    //           <label htmlFor="name">PostalAddress</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="postal_address"
    //             error={errors.postal_address ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_postal_address(e.target.value);
    //             }}
    //             value={postal_address}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Country</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="country"
    //             error={errors.country ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_country(e.target.value);
    //             }}
    //             value={country}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Qualification</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="qualification"
    //             error={errors.qualification ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_qualification(e.target.value);
    //             }}
    //             value={qualification}
    //           />
    //         </li>

    //         <li className="form-row">
    //           <label htmlFor="name">Affiliation</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="affiliation"
    //             error={errors.affiliation ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_affiliation(e.target.value);
    //             }}
    //             value={affiliation}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">University/Company</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="university_company"
    //             error={errors.university_company ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_university_company(e.target.value);
    //             }}
    //             value={university_company}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Research Interest</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="research_interest"
    //             error={errors.research_interest ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_research_interest(e.target.value);
    //             }}
    //             value={research_interest}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">
    //             Latest Qualification Certificate or CV
    //           </label>
    //           <div style={{ marginRight: "0rem" }}>
    //             <input
    //               type="file"
    //               name="lqc_cv"
    //               style={{ color: "transparent" }}
    //               onChange={(e) => {
    //                 setlqc_cv(e.target.files[0]);
    //               }}
    //             />
    //             <p style={{ color: "blue" }}>{lqc_cv.name}</p>
    //           </div>
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Scopus ID </label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="scopus_id"
    //             error={errors.scopus_id ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_scopus_id(e.target.value);
    //             }}
    //             value={scopus_id}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Orcid ID </label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="orcid_id"
    //             error={errors.orcid_id ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_orcid_id(e.target.value);
    //             }}
    //             value={orcid_id}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">Google Scholar ID</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="google_scholar_id"
    //             error={errors.google_scholar_id ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_google_scholar_id(e.target.value);
    //             }}
    //             value={google_scholar_id}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <label htmlFor="name">WoS Researcher ID</label>
    //           <TextField
    //             className="txtfld"
    //             id="outlined-basic"
    //             name="wos_researcher_id"
    //             error={errors.wos_researcher_id ? true : false}
    //             variant="outlined"
    //             fullWidth={false}
    //             onChange={(e) => {
    //               set_wos_researcher_id(e.target.value);
    //             }}
    //             value={wos_researcher_id}
    //           />
    //         </li>
    //         <li className="form-row">
    //           <Button variant="contained" color="primary" onClick={Submit}>
    //             {" "}
    //             Submit{" "}
    //           </Button>
    //         </li>
    //       </ul>
    //     </>
    //   </Section> */}
    // </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Section = styled.section`
  margin: 10px auto;
  max-width: 700px !important;
  background-color: "red !important";

  .wrapper {
    background-color: #ffffff;
    border: 2px solid #3b2774;
    list-style-type: none;
    padding: 30px 20px;
    border-radius: 10px;
  }
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.3rem;
  }
  .form-row > label {
    padding: 0.5em 8em 0.5em 0;
    flex: 1;
    color: #3b2774;
  }

  .form-row .txtfld {
    flex: 2;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }
  }
`;
