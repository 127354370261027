import axios from "axios";
import { apiUrl } from "../../config";

export const addEditorRegister = (reg) => async (dispatch) => {
  console.log("ACTION------>", reg);
  dispatch({ type: "ADD_EDITOR_REGISTER_REQUEST" });
  try {
    const response = await axios.post(
      `${apiUrl}/api/editor_register/create`,
      reg
    );
    console.log("+++ add", response);
    dispatch({ type: "ADD_EDITOR_REGISTER_SUCCESS" });
    return {
      response,
    };
  } catch (error) {
    dispatch({ type: "ADD_EDITOR_REGISTER_FAILED", payload: error });
  }
};

export const allEditorRegisterClear = (acc) => async (dispatch) => {
  try {
    dispatch({ type: "ALL_EDITOR_REGISTER_CLEAR" });
  } catch (error) {
    dispatch({ type: "ALL_EDITOR_REGISTER_FAILED", payload: error });
  }
};

export const getAllEditorRegisterLength = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/editor_register/getallregister_length`
    );
    console.log("%%%111%%%", response.data.results[0].tot);
    return { response };
  } catch (error) {}
};

export const getAllEditorRegister = (offset, row_count) => async (dispatch) => {
  dispatch({ type: "GET_EDITOR_REGISTER_REQUEST" });

  try {
    const response = await axios.get(
      `${apiUrl}/api/editor_register/getAllRegisters/${offset}/${row_count}`
    );

    dispatch({
      type: "GET_EDITOR_REGISTER_SUCCESS",
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({ type: "GET_EDITOR_REGISTER_FAILED", payload: error });
  }
};

export const updateEditorRegister = (acc) => async (dispatch) => {
  console.log("123456789__________>", acc);
  try {
    const response = await axios.post(
      `${apiUrl}/api/editor_register/update`,
      acc
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const deleteEditorRegister = (accid) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}/api/editor_register/delete`, {
      accid,
    });
    return { response };
  } catch (error) {
    alert("Something Went Wrong");
    console.log(error);
  }
};

export const searchEditorRegister = (val) => async (dispatch) => {
  const data = { id: val };
  try {
    const response = await axios.post(
      `${apiUrl}/api/editor_register/search`,
      data
    );
    return { response };
    // window.location.reload()
  } catch (error) {
    return { error };
    //alert('Something Went Wrong')
    console.log(error);
  }
};
