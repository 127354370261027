import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";

import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";
import {
  getAllPsReviewer,
  getAllPsReviewerLength,
  updatePsReviewer,
} from "../../redux/actions/paperSubmissionAction";
import { saveAs } from "file-saver";
import axios from "axios";
import download from "../../resources/img/download.png";
import SidebarReviewer from "../../components/SidebarReviewer";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

//
//
//
//
//
export default function ViewPsReviewer() {
  const classes = useStyles();

  const [id, setid] = useState("");
  const [is_edit, setis_edit] = useState(false);

  const [reviewer_status, set_reviewer_status] = useState("");
  const [reviewer_status_1, set_reviewer_status_1] = useState("");
  const [reviewer_status_2, set_reviewer_status_2] = useState("");
  const [reviewer_status_3, set_reviewer_status_3] = useState("");
  const [reviewer_status_id, set_reviewer_status_id] = useState();

  const [editor_status, set_editor_status] = useState("");

  const [errors, seterrors] = useState([]);

  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const paper_submission_state = useSelector(
    (state) => state.getAllPaperSubmissionReducer
  );
  //console.log("!!!!STATE!!!", paper_submission_state);
  const { papers } = paper_submission_state;
  //console.log("!!!!!!!", papers);

  const userState = useSelector((state) => state.loginUserReducer);

  const { error, loading, currentUser } = userState;
  //console.log("+++++++++++ CURRENT USER", currentUser);

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      console.log("-------------- DECODED", decoded.data[0]);
      var { type, username } = decoded.data[0];
      console.log("+++++++++++ USER NAME", username);

      if (type !== "reviewer") {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
    //dispatch(allPSUserClear());
    dispatch(getAllPsReviewerLength(username)).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllPsReviewer(1, itemsPerPage, username));
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(papers);
  }, [papers]);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (val) => {
    console.log("%%%%%%%%%%%%%", val);

    if (jwt_decode(userState.currentUser).data[0].username === val.rev_name_1) {
      console.log("&&&&&&&&&&&&", val.rev_name_1);
      set_reviewer_status_id(1);
      set_reviewer_status(val.reviewer_status_1);
    } else if (
      jwt_decode(userState.currentUser).data[0].username === val.rev_name_2
    ) {
      console.log("&&&&&&&&&&&&", val.rev_name_2);
      set_reviewer_status_id(2);
      set_reviewer_status(val.reviewer_status_2);
    } else if (
      jwt_decode(userState.currentUser).data[0].username === val.rev_name_3
    ) {
      console.log("&&&&&&&&&&&&", val.rev_name_3);
      set_reviewer_status_id(3);
      set_reviewer_status(val.reviewer_status_3);
    }

    setis_edit(true);
    setid(val.id);
    setOpen(true);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleClear = () => {};
  const handleDownload = (fname, fname_og) => {
    console.log("********** *******", fname, fname_og);

    const downloadAs = (url, name) => {
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = name;
          a.click();
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    downloadAs(`${apiUrl}/uploads/${fname}`, fname_og);
  };

  const Update = () => {
    const reg_data = {
      id,
      reviewer_status,
      reviewer_status_id,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updatePsReviewer(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
        dispatch(
          getAllPsReviewer(
            1,
            itemsPerPage,
            jwt_decode(userState.currentUser).data[0].username
          )
        );
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(
      getAllPsReviewer(
        Number(event.target.id),
        itemsPerPage,
        jwt_decode(userState.currentUser).data[0].username
      )
    );
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    dispatch(
      getAllPsReviewer(
        currentPage,
        itemsPerPage,
        jwt_decode(userState.currentUser).data[0].username
      )
    );
  }, [currentPage, itemsPerPage]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //
  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);

    if (!data.reviewer_status) {
      errors.reviewer_status = "*";
    }

    return errors;
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <SidebarReviewer title="Reviewer Home Page" />
        </Grid>
        <Grid item xs={11}>
          <Grid style={{ textAlign: "center" }}>
            {/* <TextField
              className="txtfld"
              id="outlined-basic"
              variant="outlined"
              fullWidth={false}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={search}
              placeholder="Search"
              style={{ backgroundColor: "white" }}
            /> */}
          </Grid>
          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#10ac84" }}>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">ManuscriptId</TableCell>
                  <TableCell align="left">PaperTitle</TableCell>
                  <TableCell align="left">AuthorName</TableCell>
                  <TableCell align="left">ReviewerName</TableCell>
                  <TableCell align="left">DateOfSubmission</TableCell>
                  <TableCell align="left">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => {
                      handleEdit(row);
                    }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.manuscript_id}</TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        handleDownload(row.paper_file, row.paper_file_og);
                      }}
                    >
                      <a href="#">{row.paper_title}</a>
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {jwt_decode(userState.currentUser).data[0].username}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.order_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      <EditIcon
                        onClick={() => {
                          handleEdit(row);
                        }}
                        style={{ color: "#ff9f43" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-5">
        <Grid item xs={12}>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>

            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </Grid>
      </Grid>

      {/*  */}
      <Grid>
        <React.Fragment>
          <Dialog
            disableEnforceFocus
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {" "}
              Current Status (
              {jwt_decode(userState.currentUser).data[0].username} ):{" "}
              {reviewer_status}
            </DialogTitle>
            <DialogContent>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3} className="mb-5">
                        <Grid item xs={12} sm={6} md={2}></Grid>

                        <Grid item xs={12} sm={6} md={8}>
                          <Box>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Current Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reviewer_status || ""}
                                label="Current Status"
                                error={errors.reviewer_status ? true : false}
                                onChange={(e) => {
                                  set_reviewer_status(e.target.value);
                                }}
                              >
                                <MenuItem value={"Minor Correction"}>
                                  Minor Correction
                                </MenuItem>
                                <MenuItem value={"Major correction"}>
                                  Major Correction
                                </MenuItem>
                                <MenuItem value={"Accepted"}>Accepted</MenuItem>
                                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={2}></Grid>
                      </Grid>

                      <Grid container spacing={3} className="mt-5">
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={Update}
                          >
                            {is_edit === false ? "Submit" : "Update"}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item sm={1}></Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>
      {/*  */}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
