export const getAddPaperSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_PS_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_PS_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_PS_FAILED":
      return {
        loading: false,
        error: action.payload,
      };

    case "ALL_PS_CLEAR":
      return {
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};

export const getAllPaperSubmissionReducer = (
  state = { papers: [] },
  action
) => {
  switch (action.type) {
    case "GET_PS_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_PS_SUCCESS":
      return {
        loading: false,
        papers: action.payload,
      };
    case "GET_PS_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllReviewerNamesReducer = (
  state = { reviewer_names: [] },
  action
) => {
  switch (action.type) {
    case "GET_RNAME_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_RNAME_SUCCESS":
      return {
        loading: false,
        reviewer_names: action.payload,
      };
    case "GET_rNAME_FAILED":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
