import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import {
  getAllPsEditor,
  getAllPsEditorLength,
  getAllReviewerNames,
  updatePsEditor,
  updatePsEditor_1,
  updatePsEditor_2,
  updatePsEditor_3,
} from "../../redux/actions/paperSubmissionAction";
import view from "../../resources/img/view.png";
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from "../../components/OnLineErrors";

import { saveAs } from "file-saver";
import axios from "axios";
import download from "../../resources/img/download.png";
import Sidebar from "../../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import {
  Box,
  Select as Dropdown,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import UpdateIcon from "@material-ui/icons/Update";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
var datetime = new Date();
var tdy_date = datetime
  .toISOString()
  .slice(0, 10)
  .split("-")
  .reverse()
  .join("-");
var t_date = datetime.toISOString().slice(0, 10).split("-").join("-");

//
//
//
//
//
//
//
//
export default function ViewPsEditor() {
  const classes = useStyles();

  const [id, setid] = useState("");
  const [is_edit, setis_edit] = useState(false);
  const [editor_status, seteditor_status] = useState("");

  const [errors, seterrors] = useState([]);

  const [reviewerlist, setreviewerlist] = useState([]);
  const [rev_name, setrev_name] = useState("");
  const [rev_name_val, setrev_name_val] = useState("");

  const [rev_name_1, setrev_name_1] = useState("");
  const [rev_name_val_1, setrev_name_val_1] = useState("");
  const [rev_name_2, setrev_name_2] = useState("");
  const [rev_name_val_2, setrev_name_val_2] = useState("");
  const [rev_name_3, setrev_name_3] = useState("");
  const [rev_name_val_3, setrev_name_val_3] = useState("");

  const [r_name, setr_name] = useState("");
  const [search, setsearch] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units === "*" ? "red" : "gray",
    }),
  };

  const paper_submission_state = useSelector(
    (state) => state.getAllPaperSubmissionReducer
  );
  console.log("!!!!STATE!!!", paper_submission_state);
  const { papers } = paper_submission_state;
  console.log("!!!!!!!", papers);

  const userState = useSelector((state) => state.loginUserReducer);

  const rnState = useSelector((state) => state.getAllReviewerNamesReducer);
  const reviewer_names = rnState.reviewer_names;
  console.log("!!!!RNAME!!!", reviewer_names);

  //console.log("+++++++++++ CURRENT USER", userState);
  const { error, loading, currentUser } = userState;

  useEffect(() => {
    if (currentUser.length > 0) {
      var decoded = jwt_decode(userState.currentUser);
      if (decoded.role !== "admin") {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }

    //dispatch(allPSUserClear());
    dispatch(getAllPsEditorLength()).then((response) => {
      setTot(response.response.data.results[0].tot);
    });
    dispatch(getAllPsEditor(1, itemsPerPage));
    dispatch(getAllReviewerNames());
    handleReviewerList();
  }, []);

  //console.log('!!!!--USER-!!!',user)

  useEffect(() => {
    setData(papers);
  }, [papers]);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClose = () => {
    setOpen(false);
  };

  const handleReviewerList = () => {
    setreviewerlist(reviewer_names);
  };
  console.log("@@@@@@", reviewerlist);
  let reviewername_options = reviewerlist.map(function (rv) {
    return { value: rv.username, label: rv.username };
  });
  console.log("#######", reviewername_options);

  const handleEdit = (val) => {
    console.log("%%%%%%%%%%%%%", val.id);
    setis_edit(true);
    setid(val.id);

    setOpen(true);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const handleClear = () => {};
  const handleDownload = (fname, fname_og) => {
    console.log("********** *******", fname, fname_og);

    const downloadAs = (url, name) => {
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = name;
          a.click();
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    downloadAs(`${apiUrl}/uploads/${fname}`, fname_og);
  };

  const Update = () => {
    const reg_data = {
      id,
      rev_name,
      rev_name_1,
      rev_name_2,
      rev_name_3,
      editor_status,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updatePsEditor(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
        dispatch(getAllPsEditor(1, itemsPerPage));
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  const Update_rev_1 = () => {
    const reg_data = {
      id,
      rev_name_1,
      editor_status,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate1(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updatePsEditor_1(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
        dispatch(getAllPsEditor(1, itemsPerPage));
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };
  const Update_rev_2 = () => {
    const reg_data = {
      id,
      rev_name_2,
      editor_status,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate2(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updatePsEditor_2(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
        dispatch(getAllPsEditor(1, itemsPerPage));
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };
  const Update_rev_3 = () => {
    const reg_data = {
      id,
      rev_name_3,
      editor_status,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate3(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updatePsEditor_3(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
        dispatch(getAllPsEditor(1, itemsPerPage));
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  // ------------------------- EDIT START --------------------------- //

  // ------------------------- Pagination start ------------------------------ //

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [tot, setTot] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [data, setData] = useState([]);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
    dispatch(getAllPsEditor(Number(event.target.id), itemsPerPage));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  useEffect(() => {
    dispatch(getAllPsEditor(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleLoadMore = () => {
    setcurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setitemsPerPage(itemsPerPage + 5);
  };

  // ------------------------ Pagination end ------------------------ //

  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.rev_name_1) {
      errors.rev_name_1 = "*";
    }
    if (!data.rev_name_2) {
      errors.rev_name_2 = "*";
    }
    if (!data.rev_name_3) {
      errors.rev_name_3 = "*";
    }
    if (!data.editor_status) {
      errors.editor_status = "*";
    }

    return errors;
  };

  const validate1 = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.rev_name_1) {
      errors.rev_name_1 = "*";
    }
    if (!data.editor_status) {
      errors.editor_status = "*";
    }
    return errors;
  };

  const validate2 = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.rev_name_2) {
      errors.rev_name_2 = "*";
    }
    if (!data.editor_status) {
      errors.editor_status = "*";
    }
    return errors;
  };
  const validate3 = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.rev_name_3) {
      errors.rev_name_3 = "*";
    }
    if (!data.editor_status) {
      errors.editor_status = "*";
    }
    return errors;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Sidebar title="View Submission Details" />
        </Grid>
        <Grid item xs={11}>
          <Grid style={{ textAlign: "center" }}>
            {/* <TextField
              className="txtfld"
              id="outlined-basic"
              variant="outlined"
              fullWidth={false}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={search}
              placeholder="Search"
              style={{ backgroundColor: "white" }}
            /> */}
          </Grid>
          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#5f27cd" }}>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">ManuscriptId</TableCell>
                  <TableCell align="left">PaperTitle</TableCell>
                  <TableCell align="left">AuthorName</TableCell>
                  <TableCell align="left">DateOfSubmission</TableCell>
                  <TableCell align="left">Reviewers</TableCell>
                  <TableCell align="left">Reviewer Status</TableCell>
                  <TableCell align="left">Editor Status</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => {
                      handleEdit(row);
                    }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.manuscript_id}</TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        handleDownload(row.paper_file, row.paper_file_og);
                      }}
                    >
                      <a href="#">{row.paper_title}</a>
                    </TableCell>

                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {moment(row.order_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      <ol>
                        <li> {row.rev_name_1} </li>
                        <li> {row.rev_name_2} </li>
                        <li> {row.rev_name_3} </li>
                      </ol>
                    </TableCell>
                    <TableCell align="left">
                      <ol>
                        <li> {row.reviewer_status_1} </li>
                        <li> {row.reviewer_status_2} </li>
                        <li> {row.reviewer_status_3} </li>
                      </ol>
                    </TableCell>
                    <TableCell align="left">{row.editor_status}</TableCell>
                    <TableCell align="left">
                      <EditIcon
                        onClick={() => {
                          handleEdit(row);
                        }}
                        style={{ color: "#ff9f43" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* PAGINATION */}
      <Grid container spacing={3} className="mt-5">
        <Grid item xs={12}>
          <div className="pageNumbers">
            <button
              onClick={handlePrevbtn}
              disabled={currentPage == pages[0] ? true : false}
            >
              {" "}
              &lt;&lt;
            </button>

            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <button
              onClick={handleNextbtn}
              disabled={currentPage == pages[pages.length - 1] ? true : false}
            >
              {" "}
              &gt;&gt;
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 50px",
            }}
          >
            <button onClick={handleLoadMore} className="loadmore">
              Load More
            </button>
          </div>
        </Grid>
      </Grid>

      {/*  */}
      <Grid>
        <React.Fragment>
          <Dialog
            disableEnforceFocus
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title"></DialogTitle>
            <DialogContent>
              <>
                <Grid container spacing={3}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3} className="mb-5">
                        <Grid item xs={12} sm={6} md={3}>
                          <Box style={{ marginTop: "4.2rem" }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Editor Status
                              </InputLabel>
                              <Dropdown
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={editor_status}
                                label="Current Status"
                                error={errors.editor_status ? true : false}
                                onChange={(e) => {
                                  seteditor_status(e.target.value);
                                }}
                              >
                                <MenuItem value={"Assign Reviewers"}>
                                  Assign Reviewers
                                </MenuItem>
                                <MenuItem value={"Sent Back to Authors"}>
                                  Sent Back to Authors
                                </MenuItem>
                                <MenuItem value={"Revision Required"}>
                                  Revision Required
                                </MenuItem>
                                <MenuItem value={"Revision Received"}>
                                  Revision Received
                                </MenuItem>
                                <MenuItem value={"accepted"}>Accepted</MenuItem>
                                <MenuItem value={"rejected"}>Rejected</MenuItem>
                              </Dropdown>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <p>
                            Reviewer 1{" "}
                            <IconButton
                              color="secondary"
                              onClick={Update_rev_1}
                            >
                              <UpdateIcon />
                            </IconButton>
                          </p>
                          <Select
                            value={rev_name_val_1}
                            onChange={(e) => {
                              setrev_name_val_1(e);
                              setrev_name_1(e.value);
                            }}
                            options={reviewername_options}
                            placeholder={"Search..."}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <p>
                            Reviewer 2{" "}
                            <IconButton
                              color="secondary"
                              onClick={Update_rev_2}
                            >
                              <UpdateIcon />
                            </IconButton>
                          </p>
                          <Select
                            value={rev_name_val_2}
                            onChange={(e) => {
                              setrev_name_val_2(e);
                              setrev_name_2(e.value);
                            }}
                            options={reviewername_options}
                            placeholder={"Search..."}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <p>
                            Reviewer 3{" "}
                            <IconButton
                              color="secondary"
                              onClick={Update_rev_3}
                            >
                              <UpdateIcon />
                            </IconButton>
                          </p>
                          <Select
                            value={rev_name_val_3}
                            onChange={(e) => {
                              setrev_name_val_3(e);
                              setrev_name_3(e.value);
                            }}
                            options={reviewername_options}
                            placeholder={"Search..."}
                          />
                        </Grid>
                      </Grid>

                      <br></br>
                      <Grid container spacing={3} className="mt-5">
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={Update}
                          >
                            {is_edit === false ? "Submit" : "Update"}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item sm={1}></Grid>
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>
      {/*  */}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
