import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  TextField,
  Button,
  Checkbox,
  Grid,
  Paper,
  Icon,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import { Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";

import { loginUser } from "../../redux/actions/userActions";
import { addAuthorRegister } from "../../redux/actions/authorRegisterAction";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Navbar from "../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";

export default function Login() {
  const classes = useStyles();

  const [title, set_title] = React.useState("");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [designation, set_designation] = useState("");
  const [ins_org, set_ins_org] = useState("");
  const [address, set_address] = useState("");
  const [city, set_city] = useState("");
  const [state, set_state] = useState("");
  const [zip_postal_code, set_zip_postal_code] = useState("");
  const [country, set_country] = useState("");
  const [email, set_email] = useState("");
  const [contact_number, set_contact_number] = useState("");
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");

  const [checked, setchecked] = useState(false);

  const [errors, seterrors] = useState([]);

  //

  const [is_edit, setis_edit] = useState(false);

  //

  const loginstate = useSelector((state) => state.loginUserReducer);
  const { error, loading } = loginstate;

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleClear = () => {
    set_title("");
    set_first_name("");
    set_last_name("");
    set_designation("");
    set_ins_org("");
    set_address("");
    set_city("");
    set_state("");
    set_zip_postal_code("");
    set_country("");
    set_email("");
    set_contact_number("");
    set_username("");
    set_password("");
    set_confirm_password("");
  };

  const onChangeContact = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      //this.setState({value: e.target.value})
      set_contact_number(e.target.value);
      if (e.target.value.length > 10) {
        errors.contact_number = true;
      } else {
        errors.contact_number = false;
      }
    }
  };
  const onChangeEmail = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    set_email(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      errors.email = true;
    } else {
      errors.email = false;
    }
  };

  const handleCheck = (e) => {
    this.setState({ checked: e.target.checked }, () => {
      if (this.state.checked === true) {
        this.setState({ page: "consentpage" });
      } else {
        this.setState({ page: "doctorspage" });
      }
    });
  };

  const Submit = () => {
    const reg_data = {
      title,
      first_name,
      last_name,
      designation,
      ins_org,
      address,
      city,
      state,
      zip_postal_code,
      country,
      email,
      contact_number,
      username,
      password,
      confirm_password,
    };
    console.log("##### REG DATA  ######", reg_data);
    const errors = validate(reg_data);
    console.log("##### ERRORS  ######", errors);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(addAuthorRegister(reg_data)).then((response) => {
        console.log("@#@#@#@#", response.response.data.msg);
        alert(response.response.data.msg);
        handleClear();
      });
    } else {
      console.log("@#@#@#@#", errors);
    }
  };

  const validate = (data) => {
    const errors = {};
    console.log("-------- DATA  -------", data);
    if (!data.title) {
      errors.title = "*";
    }
    if (!data.first_name) {
      errors.first_name = "*";
    }
    if (!data.last_name) {
      errors.last_name = "*";
    }
    if (!data.designation) {
      errors.designation = "*";
    }
    if (!data.ins_org) {
      errors.ins_org = "*";
    }
    if (!data.address) {
      errors.address = "*";
    }
    if (!data.city) {
      errors.city = "*";
    }
    if (!data.state) {
      errors.state = "*";
    }
    if (!data.zip_postal_code) {
      errors.zip_postal_code = "*";
    }
    if (!data.country) {
      errors.country = "*";
    }
    if (!data.contact_number || data.contact_number.length > 10) {
      errors.contact_number = true;
    }

    if (!data.email) {
      errors.email = true;
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(data.email)) {
        errors.email = true;
      }
    }
    if (!data.username) {
      errors.username = "*";
    }
    if (!data.password) {
      errors.password = "*";
    }
    if (data.password !== data.confirm_password) {
      errors.confirm_password = "*";
    }
    return errors;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h2 style={{ color: "#3f51b5" }}>Author Registration Form</h2>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={1}>
          {/* <Paper className={classes.paper}>
            <Sidebar title="Author Registration" />
          </Paper> */}
        </Grid>

        <Grid item xs={10} style={{ marginTop: "2rem" }}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="demo-simple-select-label">Title</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={title}
                      label="Title"
                      error={errors.title ? true : false}
                      onChange={(e) => {
                        set_title(e.target.value);
                      }}
                    >
                      <MenuItem value={"Dr"}>Dr</MenuItem>
                      <MenuItem value={"Mr"}>Mr</MenuItem>
                      <MenuItem value={"Mrs"}>Mrs</MenuItem>
                      <MenuItem value={"Miss"}>Miss</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  onChange={(e) => {
                    set_first_name(e.target.value);
                  }}
                  value={first_name}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={true}
                  error={errors.first_name ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  onChange={(e) => {
                    set_last_name(e.target.value);
                  }}
                  value={last_name}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.last_name ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Designation"
                  name="designation"
                  error={errors.designation ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  onChange={(e) => {
                    set_designation(e.target.value);
                  }}
                  value={designation}
                />
              </Grid>
            </Grid>
            <br /> <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Institute / Organization"
                  onChange={(e) => {
                    set_ins_org(e.target.value);
                  }}
                  value={ins_org}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.ins_org ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  onChange={(e) => {
                    set_address(e.target.value);
                  }}
                  value={address}
                  variant="outlined"
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.address ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="City"
                  name="city"
                  error={errors.city ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_city(e.target.value);
                  }}
                  value={city}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="State"
                  name="state"
                  error={errors.state ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_state(e.target.value);
                  }}
                  value={state}
                />
              </Grid>
            </Grid>
            <br /> <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Zip / Postal Code"
                  name="zip_postal_code"
                  error={errors.zip_postal_code ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_zip_postal_code(e.target.value);
                  }}
                  value={zip_postal_code}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Country"
                  name="country"
                  error={errors.country ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_country(e.target.value);
                  }}
                  value={country}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  onChange={(e) => onChangeEmail(e)}
                  variant="outlined"
                  value={email}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.email ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Contact Number"
                  // onChange={onChangeContact}
                  onChange={(e) => onChangeContact(e)}
                  variant="outlined"
                  value={contact_number}
                  fullWidth={true}
                  autoFocus={false}
                  error={errors.contact_number ? true : false}
                />
              </Grid>
            </Grid>
            <br /> <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Username"
                  name="username"
                  error={errors.username ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_username(e.target.value);
                  }}
                  value={username}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  type="password"
                  name="password"
                  error={errors.password ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_password(e.target.value);
                  }}
                  value={password}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-basic"
                  label="Confirm Password"
                  type="password"
                  name="confirm_password"
                  error={errors.confirm_password ? true : false}
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    set_confirm_password(e.target.value);
                  }}
                  value={confirm_password}
                />
              </Grid>
            </Grid>
            <br /> <br />
            <Grid container spacing={3} className="mt-5">
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={Submit}
                >
                  {is_edit === false ? "Submit" : "Update"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
            </Grid>
          </Paper>

          <Grid className="mt-3"></Grid>
        </Grid>

        <Grid item sm={1}></Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
